<template>
  <div>
    <div class="row align-items-end">
      <div class="col-lg-12">
        <h3 class="form-edit-header">{{ $t('video.main_settings') }}</h3>
        <app-select
          v-model="video.defaultSite"
          @blur="validations.video.defaultSite.$touch()"
          :error="validations.video.defaultSite.$error"
          :options="sites"
          id="video_defaultSite"
          :label="$t('video.default_site')"
          :disabled="disabled"
          required
        >
        </app-select>
      </div>
      <div class="col-lg-12">
        <div class="form-group">
          <label :class="{'error': validations.video.categories.$error}">
            {{ $t('video.categories') }}
            <span class="required">*</span>
          </label>
          <app-multi-select
            v-model="categoriesIds"
            :options="categories"
            :disabled="disabled"
            label="title"
            track-by="id"
            id="video_categories"
            data-attr="videoCategories_list"
          >
          </app-multi-select>
        </div>
      </div>
      <div v-if="videoShows && !vlm" class="col-lg-12">
        <label>{{ $t('video.video_show') }}</label>
        <app-multi-select
          v-model="videoShowsIds"
          :options="videoShows"
          :disabled="disabled"
          label="title"
          track-by="id"
          id="video_videoShows"
          data-attr="videoVideoShows_list"
        >
        </app-multi-select>
      </div>
      <div v-if="videoCategories && !vlm" class="col-lg-12">
        <label>{{ $t('video.video_category') }}</label>
        <app-multi-select
          v-model="videoCategoriesIds"
          :options="videoCategories"
          :disabled="disabled"
          label="title"
          track-by="id"
          id="video_videoCategories"
          data-attr="plusCategories_list"
        >
        </app-multi-select>
      </div>
      <div v-if="videoShows && !vlm" class="col-lg-12">
        <app-input
          v-model.number="video.setting.episodeNumber"
          :disabled="disabled || !video.expanded.videoShows || video.expanded.videoShows.length === 0"
          :value="1"
          type="number"
          id="video_episode_number"
          :label="$tc('video.video_episode_number')"
          data-test="video_episode_number"
          :min="1"
          :error="validations.video.setting.episodeNumber.$error"
        >
        </app-input>
      </div>
      <div class="col-lg-12">
        <div class="form-group">
          <app-safety-topics
            :video="video"
            :disabled="disabled"
            :error="validations.video.safetyTopics.$error"
            data-test="video_theme"
          >
          </app-safety-topics>
        </div>
      </div>
      <div class="col-lg-12">
        <div class="form-group">
          <app-nature-of-the-video
            :video="video"
            :disabled="disabled"
            :error="validations.isNatureOfVideo.$error"
            data-test="video_type"
          >
          </app-nature-of-the-video>
        </div>
        <div class="form-group">
          <app-video-source-type
            :video="video"
            :disabled="disabled"
            :error="validations.isVideoSourceType.$error"
            data-test="video_source"
          >
          </app-video-source-type>
        </div>
        <div class="form-group">
          <app-input
            :label="$t('video.video_source_gdpr')"
            :disabled="disabled"
            v-model.trim="video.meta.gdprSource"
            :error="validations.video.meta.gdprSource.$error"
            data-test="video_source_gdpr"
          >
          </app-input>
        </div>
      </div>
      <div class="col-lg-12">
        <div class="video-settings">
          <app-checkbox
            v-if="showCheckbox(video.setting.forAdults)"
            v-model="video.setting.forAdults"
            :disabled="disabled"
            id="video_setting_forAdults"
            :label="$t('video.setting_for_adults')"
            data-test="video_setting_for_adults"
          >
          </app-checkbox>
          <app-checkbox
            v-if="showCheckbox(video.setting.productPlacement)"
            v-model="video.setting.productPlacement"
            :disabled="disabled"
            id="video_setting_productPlacement"
            :label="$t('video.setting_product_placement')"
            data-test="video_setting_product_placement"
          >
          </app-checkbox>
          <app-checkbox
            v-if="showCheckbox(video.setting.exclusive)"
            v-model="video.setting.exclusive"
            :disabled="disabled"
            id="video_setting_exclusive"
            :label="$t('video.setting_exclusive')"
            data-test="video_setting_exclusive"
          >
          </app-checkbox>
          <app-checkbox
            v-if="showCheckbox(video.setting.noAds)"
            v-model="video.setting.noAds"
            :disabled="disabled"
            id="video_setting_noAds"
            :label="$t('video.setting_no_ads')"
            data-test="video_setting_no_ads"
          >
          </app-checkbox>
          <app-checkbox
            v-if="showCheckbox(video.setting.dynamicPreview)"
            v-model="video.setting.dynamicPreview"
            :disabled="disabled"
            id="video_setting_dynamicPreview"
            :label="$t('video.setting_dynamic_preview')"
            data-test="video_setting_dynamic_preview"
          >
          </app-checkbox>
        </div>
      </div>
      <div class="col-lg-6">
        <app-input
          v-if="video.setting.dynamicPreview"
          v-model="video.setting.dynamicPreviewStart"
          :disabled="disabled"
          type="number"
          :step=1
          id="video_setting_dynamicPreviewStart"
          :label="$t('video.setting_dynamic_preview_start')"
          :error="validations.video.setting.dynamicPreviewStart.$error"
          data-test="video_setting_dynamic_preview_start"
        >
        </app-input>
      </div>
      <div class="col-lg-6">
        <app-input
          v-if="video.setting.dynamicPreview"
          v-model="video.setting.dynamicPreviewDuration"
          :disabled="disabled"
          type="number"
          :step=1
          id="video_setting_dynamicPreviewDuration"
          :label="$t('video.setting_dynamic_preview_duration')"
          :error="validations.video.setting.dynamicPreviewDuration.$error"
          data-test="video_setting_dynamic_preview_duration"
        >
        </app-input>
      </div>
    </div>
  </div>
</template>

<script>
import Input from '@/components/form/inputs/Input'
import Select from '@/components/form/select/Select'
import MultiSelect from '@/components/form/select/MultiSelect'
import Checkbox from '@/components/form/Checkbox'
import SafetyTopics from '../../components/video/VideoSafetyTopics'
import NatureOfTheVideo from '../../components/video/NatureOfTheVideo'
import VideoSourceType from '../../components/video/VideoSourceType'
import { mapGetters } from 'vuex'
import { MODULE_VIDEO } from '@/model/ValueObject/UserPermissionModules'

export default {
  name: 'VideoMainSettings',
  components: {
    appInput: Input,
    appSelect: Select,
    appMultiSelect: MultiSelect,
    appCheckbox: Checkbox,
    appNatureOfTheVideo: NatureOfTheVideo,
    appVideoSourceType: VideoSourceType,
    appSafetyTopics: SafetyTopics
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    video: {
      type: Object,
      required: true
    },
    validations: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters(['vlm']),
    videoShows () {
      const videoShows = this.$store.getters['videoShow/all']
      return videoShows.filter(videoShow => {
        if (videoShow.enabled) {
          return videoShow
        }
        if (this.video.videoShows && this.video.videoShows.includes(videoShow.id)) {
          return videoShow
        }
      })
    },
    videoCategories () {
      const videoCategories = this.$store.getters['videoCategory/all']
      return videoCategories.filter(videoCategory => {
        if (videoCategory.enabled) {
          return videoCategory
        }
        if (this.video.videoCategories && this.video.videoCategories.includes(videoCategory.id)) {
          return videoCategory
        }
      })
    },
    categoriesIds: {
      set (newValue) {
        this.video.categories = this._.map(newValue, 'id')
      },
      get () {
        return this.categories.filter(object => this.video.categories.includes(object.id))
      }
    },
    videoShowsIds: {
      set (newValue) {
        this.video.videoShows = this._.map(newValue, 'id')
      },
      get () {
        return this.videoShows.filter(object => this.video.videoShows.includes(object.id))
      }
    },
    videoCategoriesIds: {
      set (newValue) {
        this.video.videoCategories = this._.map(newValue, 'id')
      },
      get () {
        return this.videoCategories.filter(object => this.video.videoCategories.includes(object.id))
      }
    },
    sites () {
      return this.$store.getters['site/enabledSitesForForm'](MODULE_VIDEO, this.video, this.disabled)
    },
    categories () {
      return this.$store.getters['category/all']
    }
  },
  methods: {
    showCheckbox (value) {
      if (!this.disabled || (this.disabled && value)) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.form-edit-header {
  font: 400 rem(22px) "Roboto";
  color: #465674;
}
.video-settings{
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1.125rem;
  > * {
    margin-right: 1rem;
  }
}
::v-deep .btn {
  color: #465674 !important;
  background: #fff !important;
  border: 1px solid #465674 !important;
  &.active {
    color: #fff !important;
    background: #6599FE !important;
    border-color: #6599FE !important;
  }
}
</style>
